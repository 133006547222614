module.exports ={
  "propertyService": "https://cd7yrxi3e4.execute-api.us-east-1.amazonaws.com/ardalan4",
  "domusoApiService": "https://uxtiu26l2e.execute-api.us-east-1.amazonaws.com/ardalan4",
  "appService": "https://domusograilsweb-ardalan4.devmuso.com",
  "reservationService": "https://zufg1z83kg.execute-api.us-east-1.amazonaws.com/ardalan4",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://yrdg1hh418.execute-api.us-east-1.amazonaws.com/ardalan4",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-ardalan4.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-ardalan4.devmuso.com",
  "maintenanceUrl": "https://yrdg1hh418.execute-api.us-east-1.amazonaws.com/ardalan4/maintenance",
  "env": "ardalan4"
}